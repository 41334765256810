import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        nav: [
            { name: "HOME",key:"index" },
            { name: "ABOUT US",key:"aboutUs" },

            { name: "EQUIPMENT",key:"equipment" },
            { name: "CLIENTS",key:"client" },
            { name: "CULTURE", key: "culture" },
            { name: "CONTACT US" , key: "contact"},
        ],
        bg: "",
    },
    mutations: {
        setState (state, data) {
            state[data.key] = data.value
        }
    },
    actions: {
        getData (store) {
            window.http({
                url: "index",
                method:"GET",
                
            }).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                    store.commit("setState", { key: "bg" , value: res.data.data.bg })
                } 
            })
        },
        getContent () {
            window.http({
                url: "content",
                params: {
                    client: "client"
                }
            }).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                    let menu = []
                    res.data.data.data.forEach(item => {
                        item.content.forEach(c => {
                            c.content = JSON.parse(c.content)
                        })

                        //dectection
                        if (item.id == 4) {
                            menu.push({ name:item.title,key: "dectection" })
                        }
                        //pipe
                        if (item.id== 3) {
                            menu.push({ name:item.title,key: "pipe" })
                        }
                        if (item.id != 1&&item.id != 2 && item.id != 3&&item.id != 4) {
                            menu.push({ name: item.title, key: item.title})
                        }
                        

                    })

                    menu.reverse()
                    //每次修改菜单前先重置
                    let nav = [
                        { name: "HOME",key:"index" },
                        { name: "ABOUT US",key:"aboutUs" },

                        { name: "EQUIPMENT",key:"equipment" },
                        { name: "CLIENTS",key:"client" },
                        { name: "CULTRUE", key: "culture" },
                        { name: "CONTACT US" , key: "contact"},
                    ]
                    menu.forEach(item => {
                        nav.splice(2,0,item)
                    })
                    store.commit("setState", { key: "nav" , value: nav })
                }
            })
        },
    }
})

// import { reactive } from "vue"

// const store = reactive({
//     count: 1,
//     bg: "",
//     setCount () {
//         this.count++
//     },
//     nav: [
//         { name: "index",key:"index" },
//         { name: "aboutUs",key:"aboutUs" },

//         { name: "EQUIPMENT",key:"equipment" },
//         { name: "CLIENTS",key:"client" },
//         { name: "culture", key: "culture" },
//         { name: "contact" , key: "contact"},
//     ]
// })
export default store