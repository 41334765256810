export default [
    {
        path:"/",
        name: "index",
        component: () => import("@/view/index/index.vue")
    },
    {
        path: "/news",
        name: "news",
        component: () => import ("@/view/news/news.vue")
    },
    {
        path: "/dectection",
        name: "dectection",
        component: () => import ("@/view/dectection/dectection.vue")
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import ("@/view/contact/contact.vue")
    },
    {
        path: "/culture",
        name: "culture",
        component: () => import ("@/view/culture/culture.vue")
    },
    {
        path: "/imgDetail",
        name: "imgDetail",
        component: () => import ("@/view/culture/imgDetail.vue")
    },
]