<template>
    <div>
        <div class="ba">Copyright © 2017 Waterland Group . All Rights Reserved. |</div>
        <div class="ba">Designed & powered by Magic Square Studio.</div>
        <br><br>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.ba {
    color: #838DB1;
}
</style>