<style lang="less">
    
    ul {
        margin: 0;
        padding: 0;
        
    }
    // li {
    //     list-style: none;
    // }
    #app {
        // background: #102269;
        min-height:100vh;
        // color:#FFF;
    }
    .container {
        max-width: 730px;
        margin: auto;
        box-sizing: border-box;
    }
    .el-drawer {
        background: #212121 !important;;
    }
    .menuIcon {
        width: 60px;
        height: 53px;
        background: #000;
        position: fixed;
        left: 0px;
        top: 0px;
        box-sizing: border-box;
        >div {
            border-top: solid #FFF 1px;
            width: 41%;
            margin: auto;
        }
        display: flex;
        padding: 1.05em 0;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-around;
        cursor: pointer;
        z-index: 200;
        transition: 0.8s;
        &:hover {
            box-shadow: 0 0 5px #FFF ;
        }
    }
    
    .blueBtn {
        background: #637FB7;
        color: #FFF;
        min-width: 100px;
        padding: 10px 0;
        text-align: center;
        cursor: pointer;
        user-select: none;
        &:hover {
            opacity:0.8;
        }
        &:active {
            opacity: 0.6 !important;
        }
    }
    .ivu-drawer-body {
        background: #212121;
        padding: 0 !important;
    }
    .drawerMain {
        height: 100%;
        
    }
    .menuUl {
        color: #AAAAAA;
        height: 100%;
        padding-top:100px;
        >li {
            padding: 15px 20px;  
            font-size:15px;border-bottom: solid #AAAAAA 1px;
            transition: 0.3s;
            cursor: pointer;
            &:hover {
                background:rgb(63, 63, 55);
            }
        }
        >li:last-child {
            border: none !important;
        }
    }
    .aText ul,.aText li{
        color: white !important;
    }
    .fixMenu {
        width: 140px;
        // background:#36528A;
        position: fixed;
        top: 50%;
        right: 0;
        // box-shadow: 0 0 5px #FFF;
        transform: translateY(-50%);
        // border: solid #FCFCFC 1px;
        transition: 0.8s;
        z-index: 100;
        overflow:hidden;
        ul {
            >li {
                // border-top: solid #FCFC
                background: #36528a;
                transition: 0.5s;
                &:hover {
                    background:#3058a8 !important;
                }
                a {
                    margin-left: 15%;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    padding: 0.8em 0;
                    color: #FFF;
                }
            }
            >li:first-child {
                border: none !important;
            }
        }
        
        &:hover {
            box-shadow: 0 0 5px #FFF,0 0 15px #FFF,0 0 25px #FFF !important;
        }
    }
    .topImg {
        position: absolute;
        left: 0;
        top: 0;
    }
    // background:url("http://www.waterland.com.hk/wp-content/uploads/2016/05/waterlandBG.jpg") 100% 0% / 800px repeat scroll padding-box border-box rgb(18, 37, 103);
    .App {
        // border: solid red 1px;
        min-height: 100vh;
    }
    .mShow {
        display: none;
    }
    .mShow2 {
        display: none;
    }
    .topImg1 {
        width: 28vw;
        height:23vw;
        background: #FFF;
        margin-left: 30px;
        margin-bottom: -80px;
        padding: 20px;
        >img {
            width: 100%;
            height: 100%;
        }
    }
    .menuButtom {
        width: 80px;
        background: #212121;
        position: absolute;
        right: 0;
        color: #FFF;
        padding: 10px 15px;;
        top: 0;
        border: solid #000 1px;
        >img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
    .fixMenu2 {
        width: 140px;
        position: fixed;
        top: 42px;
        right: 0;
        transition: 0.8s;
        z-index: 100;
        overflow:hidden;
        ul {
            >li {
                background: #36528a;
                transition: 0.5s;
                &:hover {
                    background:#3058a8 !important;
                }
                a {
                    margin-left: 15%;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    padding: 0.8em 0;
                    color: #FFF;
                }
            }
            >li:first-child {
                border: none !important;
            }
        }
    }
    .lh {
        line-height: 1.15em;
    }
    .loopMask {
        z-index: 1;
        width: 100vw;
        height: 100vh;
        background: black;
        opacity: 0.6;
        position: fixed;
        left: 0;
        top: 0;
    }
    .z10 {
        z-index: 10;
    }
    .loopBox {
        width: 800px;
        // height: 30vw;
        border: solid #FFF 5px;
        border-radius: 5px;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .roopImg  {
        height: 100%;
        background-size: cover !important;
        background-position: center !important;
    }
    .miar20 {
        padding: 10px 0 20px;
        background: #FFF;
    }
    .twoBtn {
        >div {
            width: 20px;
            height: 20px;
            border: #999 solid 1px;
            border-radius: 50%;
            margin: 0 2px;
            display: flex;
            cursor: pointer;
        }
        >div:hover {
            box-shadow: 0 0 3px #333;
        }
        >div:first-child::before {
            display: block;
            content: "";
            width: 0;
            height: 0;
            border: solid transparent 4px;
            border-left: 0;
            border-right: solid #666 4px;
            margin: auto;
            transform: translateX(-1px);
        }
        >div:last-child::before {
            display: block;
            content: "";
            width: 0;
            height: 0;
            border: solid transparent 4px;
            border-right: 0;
            border-left: solid #666 4px;
            margin: auto;
            transform: translateX(1px);
        }
    }
    .close {
        width: 20px;
        height: 20px;
        border: #999 solid 1px;
        border-radius: 50%;
        margin: 0 2px;
        display: flex;
        cursor: pointer;
        &:hover {
            box-shadow: 0 0 3px #333;
        }
        >img {
            width: 50%;
            height: 50%;
            display: block;
            margin: auto;
        }
    }
    .loopNav {
        position: absolute;
        left: 50%;
        bottom: 60px;
        width: 80%;
        min-height: 30px;
        border: solid 1px red;
        transform: translateX(-50%);
        z-index: 10;
    }
    .el-carousel__arrow  {
        display: none !important;
    }
</style>

<template>
    <div class="App" :style="{background: `url(${bg}) 100% 0% / 800px repeat scroll padding-box border-box rgb(18, 37, 103)`}">
        <div class="relative mNone" style="height: 100px;">
            <img src="@/assets/img/top.png" style="width: 100%;" class="topImg" alt="">
        </div>
        <!-- 移动端左上角logo -->
        <div style="height: 30px;" class="mShow"></div>
        <!-- <div class="mShow topImg1">
            <img src="@/assets/img/topLogo.png" alt="">
        </div> -->
        <!-- 移动端右上角菜单按钮 -->
        <div class="menuButtom al2 ju2 mShow2 op" @click="showMenu=!showMenu">
            <img src="@/assets/img/menu.png" /><div>MENU</div>
        </div>
        <div class="fixMenu2" v-if="showMenu">
            <ul>
                <li v-for="(item) in nav" :key="item.name"><a @click="menuClick(item)">{{ item.name }}</a></li>
            </ul>
        </div>
        
        <!-- PC端右边菜单 -->
        <div class="fixMenu mNone">
            <ul>
                <li v-for="(item) in nav" :key="item.name"><a @click="menuClick(item)">{{ item.name }}</a></li>
            </ul>
        </div>
        
        <el-backtop :right="20" :bottom="20" ></el-backtop>
        <div class="menuIcon" @click="drawer=true">
            <div></div><div></div><div></div>
        </div>

        <Drawer
            v-model="drawer"
            width="300"
            placement="left">
            <div class="drawerMain">
                <ul class="menuUl">
                    <li v-for="(item,i) in nav" :key="i" @click="menuClick(item)">{{item.name}}</li>
                </ul>
            </div>
        </Drawer>
        <router-view></router-view>
        
        
    </div>
</template>

<script>

    export default {
        name: "app",
        data () {
            return {
                drawer: false,
                showMenu: false,
            }
        },
        created () {
            this.$store.dispatch("getData")
            this.$store.dispatch("getContent")
        },
        computed: {
            nav: {
                get () { return this.$store.state.nav },
                set (val) {
                    this.$store.commit("setState", { key: "nav" , value: val })
                }
            },
            bg: {
                get () { return this.$store.state.bg },
                set (val) {
                    this.$store.commit("setState", { key: "bg" , value: val })
                }
            },
        },
        methods:{
            menuClick (item) {
                // console.log(item)
                this.showMenu = false
                this.$router.push("/")
                setTimeout(() => {
                    let ele = document.getElementById(item.key)
                    console.log(ele.offsetTop)
                    window.scrollTo({
                        top: ele.offsetTop,
                        behavior: "smooth"
                    })
                    this.drawer = false
                },100)
            }
        }
    }
</script>


