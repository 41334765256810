import Router from "vue-router"
import Vue from "vue"

Vue.use(Router)
import routes from "./routes"


const router = new Router({
    routes,
})

router.beforeEach((to,from, next) => {
    if (document.documentElement) {
       document.documentElement.scrollTop = 0 
    }
    next()
    
})

export default router