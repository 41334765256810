import axios from "axios"

// axios.defaults.baseURL = "http://192.168.1.115:5000/"
//  axios.defaults.baseURL = "http://167.179.100.249:8188/"
 axios.defaults.baseURL = "http://139.9.194.151:8188/"

axios.interceptors.request.use(config => {
    config.headers = {
        
    }
    return config   
})

export default axios