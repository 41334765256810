import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import axios from "axios"

Vue.prototype.$axios = axios

import router from "@/router/router.js"

import store from "@/store/store.js"



import http from "@/http/http.js"
window.http = http

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
// import "./main.scss"
Vue.use(Element)

import myFooter from "@/components/footer.vue"
Vue.component("myFooter",myFooter)

import { Drawer, Carousel, CarouselItem } from "view-design"
import 'view-design/dist/styles/iview.css';
Vue.component("Drawer",Drawer)
Vue.component("Carousel",Carousel)
Vue.component("CarouselItem",CarouselItem)

import myNav from "@/components/myNav.vue"
Vue.component("myNav",myNav)


import "@/assets/css/public.css";
import "@/assets/css/mobile.css";




new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
